<template>
    <div class="hotel-setup__table-content" v-if="rooms.length">
        <table class="table">
            <thead class="table__table-head">
                <th>Room</th>
                <th>Floor</th>
                <th>Average Time</th>
                <th>Options</th>
            </thead>
            <tbody>
                <tr v-for="room in rooms">
                    <td>{{ room.roomName }}</td>
                    <td>{{ room.floorName }} {{ room.floorPrefix }}</td>
                    <td>{{ room.roomAverageTime }}</td>
                    <td>
                        <div class="table__options">
                            <RoomsQrMaker :room-id="room.id"/>
                            <router-link title="Edit Room" :to="`/hotel_setup/rooms/edit/${room.id}`" >
                                <img src="@/assets/icons/vuesax/linear/edit.svg" width="15" height="15" />
                            </router-link>
                            <a href="#" title="Delete Room" @click.prevent="showModal(room.id)">
                                <img src="@/assets/icons/vuesax/linear/trash.svg" width="15" height="15" />
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Table paginator component -->
    <TablePaginator ref="paginator" :api-url="roomsListApi" :filter="filterWords" v-model="rooms" />

    <!-- Empty table records component -->
    <TableEmptyData title="Your Rooms Is Empty"
        message="We detect that you have not yet registered any room, register them before using the application..."
        v-if="!rooms.length" />

    <!-- Modal delete component -->
    <ModalDelete ref="modalDelete" @on-delete="refreshList" />
</template>

<script setup>
import { ref } from 'vue'
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'
import ModalDelete from '@/common/ModalDelete.vue'
import RoomsQrMaker from './RoomsQrMaker.vue'

defineProps(['filterWords'])

const roomsListApi = `/web/rooms`
const paginator = ref(null)
let rooms = ref([])
let modalDelete = ref(null)

function showModal(roomId) {
    const deleteRoomApi = `/web/rooms/delete/${roomId}`
    modalDelete.value.showModal(deleteRoomApi)
}

function refreshList() {
    paginator.value.refreshPage()
}
</script>