<template>
    <div class="hotel-setup">
        <div class="hotel-setup__content hotel-setup--large-content">
            <img src="@/assets/images/prosafe-new-logo.svg" class="hotel-setup__logo" alt="">

            <div class="hotel-setup__info">
                Here you can manage essential hotel information.
            </div>

            <div class="hotel-setup__options">
                <div class="hotel-setup__option-left">
                    <router-link class="button button--link button--text-medium" to="/hotel_setup">
                        <img src="@/assets/icons/vuesax/linear/arrow-left-white.svg" width="15" height="15" alt="">
                        Hotel Setup
                    </router-link>
                </div>

                <div class="hotel-setup__option-right popup-options">
                    <button class="button button--bordered button--small-padding">
                        <img src="@/assets/icons/vuesax/linear/setting-2.svg" width="15" height="15" alt="">
                        Options
                    </button>

                    <div class="popup-options__options-content">
                        <ul class="popup-options__options-list">
                            <li>
                                <router-link to="/hotel_setup/rooms/add">Add Rooms</router-link>
                            </li>
                            <li>
                                <RoomsQrSheet />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="hotel-setup__card-content hotel-setup--card-padding">
                <div class="table-title">
                    <h1 class="table-title__title">Hotel Rooms</h1>

                    <!-- Filter input component -->
                    <InputFilter v-model="words" />
                </div>

                <!-- Table component -->
                <RoomsTable :filterWords="words" />
            </div>
        </div>
    </div>
</template>

<script setup>
import InputFilter from '@/common/InputFilter.vue';
import RoomsTable from '../Components/RoomsTable.vue';
import RoomsQrSheet from '../Components/RoomsQrSheet.vue'
import { ref } from 'vue';

let words = ref("");
</script>
